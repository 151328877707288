// Primary behavior responsible for initializing the colorbox links.
Drupal.behaviors.colorboxLink = {
  attach: function (context, settings) {
    if ($.colorbox) {
      $('body', context).on('click', '.colorbox-link', function(e) {
        e.preventDefault();
        var width = $(this).attr('width') ? $(this).attr('width')
          : site.client.isMobile ? '100%'
          : '90%';
        var height = $(this).attr('height') ? $(this).attr('height')
          : site.client.isMobile ? '100%'
          : '90%';
        $.colorbox({opacity:0.9, href:$(this).attr('href'), width:width, height:height, onOpen:function(){ $('#colorbox').addClass('colorbox-link-container'); }});
      });
    }
  }
};
